body, html {
    width: 100%;
    height: 100%;
    background-color: #ffffff; }
  
  .container {
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center; }
  
  .machine {
    width: 60vmin;
    fill: #3eb049; }
  
  .small-shadow, .medium-shadow, .large-shadow {
    fill: rgba(0, 0, 0, 0.05); }
  
  .small {
    -webkit-animation: counter-rotation 2.5s infinite linear;
         -moz-animation: counter-rotation 2.5s infinite linear;
           -o-animation: counter-rotation 2.5s infinite linear;
              animation: counter-rotation 2.5s infinite linear;
    -webkit-transform-origin: 100.136px 225.345px;
        -ms-transform-origin: 100.136px 225.345px;
            transform-origin: 100.136px 225.345px; }
  
  .small-shadow {
    -webkit-animation: counter-rotation 2.5s infinite linear;
         -moz-animation: counter-rotation 2.5s infinite linear;
           -o-animation: counter-rotation 2.5s infinite linear;
              animation: counter-rotation 2.5s infinite linear;
    -webkit-transform-origin: 110.136px 235.345px;
        -ms-transform-origin: 110.136px 235.345px;
            transform-origin: 110.136px 235.345px; }
  
  .medium {
    -webkit-animation: rotation 3.75s infinite linear;
         -moz-animation: rotation 3.75s infinite linear;
           -o-animation: rotation 3.75s infinite linear;
              animation: rotation 3.75s infinite linear;
    -webkit-transform-origin: 254.675px 379.447px;
        -ms-transform-origin: 254.675px 379.447px;
            transform-origin: 254.675px 379.447px; }
  
  .medium-shadow {
    -webkit-animation: rotation 3.75s infinite linear;
         -moz-animation: rotation 3.75s infinite linear;
           -o-animation: rotation 3.75s infinite linear;
              animation: rotation 3.75s infinite linear;
    -webkit-transform-origin: 264.675px 389.447px;
        -ms-transform-origin: 264.675px 389.447px;
            transform-origin: 264.675px 389.447px; }
  
  .large {
    -webkit-animation: counter-rotation 5s infinite linear;
       -moz-animation: counter-rotation 5s infinite linear;
           -o-animation: counter-rotation 5s infinite linear;
              animation: counter-rotation 5s infinite linear;
    -webkit-transform-origin: 461.37px 173.694px;
        -ms-transform-origin: 461.37px 173.694px;
            transform-origin: 461.37px 173.694px; }
  
  .large-shadow {  
    -webkit-animation: counter-rotation 5s infinite linear;
         -moz-animation: counter-rotation 5s infinite linear;
           -o-animation: counter-rotation 5s infinite linear;
              animation: counter-rotation 5s infinite linear;
    -webkit-transform-origin: 471.37px 183.694px;
        -ms-transform-origin: 471.37px 183.694px;
            transform-origin: 471.37px 183.694px; }
  
  @-webkit-keyframes rotation {
      from {-webkit-transform: rotate(0deg);}
      to   {-webkit-transform: rotate(359deg);}
  }
  @-moz-keyframes rotation {
      from {-moz-transform: rotate(0deg);}
      to   {-moz-transform: rotate(359deg);}
  }
  @-o-keyframes rotation {
      from {-o-transform: rotate(0deg);}
      to   {-o-transform: rotate(359deg);}
  }
  @keyframes rotation {
      from {transform: rotate(0deg);}
      to   {transform: rotate(359deg);}
  }
  
  @-webkit-keyframes counter-rotation {
      from {-webkit-transform: rotate(359deg);}
      to   {-webkit-transform: rotate(0deg);}
  }
  @-moz-keyframes counter-rotation {
      from {-moz-transform: rotate(359deg);}
      to   {-moz-transform: rotate(0deg);}
  }
  @-o-keyframes counter-rotation {
      from {-o-transform: rotate(359deg);}
      to   {-o-transform: rotate(0deg);}
  }
  @keyframes counter-rotation {
      from {transform: rotate(359deg);}
      to   {transform: rotate(0deg);}
  }

  .min-container-loader{
    display: flex;
    flex-direction: column;
  }
  .logo{
    display: flex;
    justify-content: center;
  }



  @import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700);


.content {
    padding:0;
    margin:10% 15%;  
    margin-bottom: 0%;
    margin-top: 0%; 
}

.close {
    float: right;
    font-size: 21px;
    font-weight: bold;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .2;
}

.close:hover,.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    opacity: .5;
}

button.close {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
}

.alert {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
}

.alert h4 {
    margin-top: 0;
    color: inherit;
}

.alert .alert-link {
    font-weight: bold;
}

.alert>p,.alert>ul {
    margin-bottom: 0;
}

.alert>p+p {
    margin-top: 5px;
}

.alert-dismissable {
    padding-right: 35px;
}

.alert-dismissable .close {
    position: relative;
    top: -2px;
    right: -21px;
    color: inherit;
}

.alert-success {
    background-color: #dff0d8;
    border-color: #d6e9c6;
    color: #3c763d;
}

.alert-success hr {
    border-top-color: #c9e2b3;
}

.alert-success .alert-link {
    color: #2b542c;
}

.alert-info {
    background-color: #d9edf7;
    border-color: #bce8f1;
    color: #31708f;
}

.alert-info hr {
    border-top-color: #a6e1ec;
}

.alert-info .alert-link {
    color: #245269;
}

.alert-warning {
    background-color: #fcf8e3;
    border-color: #faebcc;
    color: #8a6d3b;
}

.alert-warning hr {
    border-top-color: #f7e1b5;
}

.alert-warning .alert-link {
    color: #66512c;
}

.alert-danger {
    background-color: #f2dede;
    border-color: #ebccd1;
    color: #a94442;
}

.alert-danger hr {
    border-top-color: #e4b9c0;
}

.alert-danger .alert-link {
    color: #843534;
}

.alert {
    border-radius: 0;
    -webkit-border-radius: 0;
    box-shadow: 0 1px 2px rgba(0,0,0,0.11);
}

.alert .sign {
    font-size: 20px;
    vertical-align: middle;
    margin-right: 5px;
    text-align: center;
    width: 25px;
    display: inline-block;
}

.alert-success {
    background-color: #dbf6d3;
    border-color: #aed4a5;
    color: #569745;
}

.alert-info {
    background-color: #d9edf7;
    border-color: #98cce6;
    color: #3a87ad;
}

.alert-warning {
    background-color: #fcf8e3;
    border-color: #f1daab;
    color: #c09853;
}

.alert-danger {
    background-color: #f2dede;
    border-color: #e0b1b8;
    color: #b94a48;
}

.alert-white {
    background-image: linear-gradient(to bottom,#FFFFFF,#F9F9F9);
    border-top-color: #d8d8d8;
    border-bottom-color: #bdbdbd;
    border-left-color: #cacaca;
    border-right-color: #cacaca;
    color: #404040;
    padding-left: 61px;
    position: relative;
}

.alert-white .icon {
    text-align: center;
    width: 45px;
    height: 100%;
    position: absolute;
    top: -1px;
    left: -1px;
    border: 1px solid #bdbdbd;
}

.alert-white .icon:after {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    display: block;
    content: '';
    width: 10px;
    height: 10px;
    border: 1px solid #bdbdbd;
    position: absolute;
    border-left: 0;
    border-bottom: 0;
    top: 50%;
    right: -6px;
    margin-top: -5px;
    background: #fff;
}

.alert-white.rounded {
    border-radius: 3px;
    -webkit-border-radius: 3px;
}

.alert-white.rounded .icon {
    border-radius: 3px 0 0 3px;
    -webkit-border-radius: 3px 0 0 3px;
}

.alert-white .icon i {
    font-size: 20px;
    color: #FFF;
    left: 12px;
    margin-top: -10px;
    position: absolute;
    top: 50%;
}

.alert-white.alert-danger .icon,.alert-white.alert-danger .icon:after {
    border-color: #ca452e;
    background: #da4932;
}

.alert-white.alert-info .icon,.alert-white.alert-info .icon:after {
    border-color: #3a8ace;
    background: #4d90fd;
}

.alert-white.alert-warning .icon,.alert-white.alert-warning .icon:after {
    border-color: #d68000;
    background: #fc9700;
}

.alert-white.alert-success .icon,.alert-white.alert-success .icon:after {
    border-color: #54a754;
    background: #60c060;
}